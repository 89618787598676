(function($) {
  /**
   * Event listener to refersh select dropdown.
   * Event call is coming from sites/aveda/na_ms/js/pc/site/checkout/payment.js when clicking "Add new" button in payement panel.
  */
  $(document).on('selectBox.initOne', function(event, responseObj) {
    Drupal.behaviors.selectBox.initOne($(responseObj));
  });

  $(document).on('selectBox.reInit', function(event, context) {
    Drupal.behaviors.selectBox.attach(context);
  });

  Drupal.behaviors.selectBox = {
    /**
     * Flag to ensure this JS is only attached once.
     * @type {boolean}
     */
    attached: false,

    /**
     * Builds a replacement out of div elements which are more
     * easily style-able. All attributes are copied over.
     * @param {Object} $oldSelect - old select element.
     */
    replace: function($oldSelect) {
      // Doublecheck if the old select element has already been replaced,
      // and remove the replacement dropdown to allow a refresh.
      if ($oldSelect.hasClass('js-select-box-replaced')) {
        $oldSelect.siblings('.js-select-box').remove();
        $oldSelect.removeClass('select-box--replaced js-select-box-replaced');
      }

      var $newSelect = $('<div></div>');
      var $newLabel = $('<div></div>');
      var $newOptions = $('<div></div>');

      // Copy all attributes from old select element.
      var attributes = $oldSelect.prop('attributes');
      if (attributes) {
        $.each(attributes, function() {
          if (this.name === 'disabled') {
            $newSelect.addClass('select-box--disabled js-select-box-disabled');
          }
          // Only on IE browser, the classes which were added for 'disabled' attribute are overridden
          // so here, we have included the additional classes inaddition to the 'disabled' attribute class
          if (this.name === 'class') {
            $newSelect.addClass(this.value);
          } else {
            $newSelect.attr(this.name, this.value);
          }
        });
      }

      // Add BEM and JS classes to the new select and its label.
      $newSelect.addClass('select-box js-select-box');
      $newLabel.addClass('select-box__label js-select-box-label').attr({'tabindex': '0', 'role': 'combobox', 'aria-expanded': 'false', 'id': $oldSelect.attr('id')});
      $newSelect.append($newLabel);

      // Copy over all the old select's options.
      $oldSelect.children('option').each(function() {
        var $oldOption = $(this);
        var $newOption = $('<div></div>');
        var attributes = $oldOption.prop('attributes');

        $.each(attributes, function() {
          $newOption.attr(this.name, this.value);
        });

        $newOption.text($oldOption.text());
        $newOption.addClass('select-box__option js-select-box-option');
        $newOption.attr({'role': 'gridcell', 'tabindex': '0', 'aria-selected': $newOption.attr('selected') === 'selected' ? 'true' : 'false'});
        $newOptions.append($newOption);
      });

      // Add BEM and JS classes to the new select's options div.
      $newOptions.addClass('select-box__options js-select-box-options');
      $newSelect.append($newOptions);

      // Put the new select into the DOM.
      $oldSelect.before($newSelect);
      $oldSelect.addClass('select-box--replaced js-select-box-replaced');
    },

    /**
     * Takes the currently selected option of old select and
     * shows it in the label area of the new select box.
     * If there is no option by default, then it loads the first option.
     * @param {Object} $oldSelect - old select element.
     */
    setLabel: function($oldSelect) {
      var selectedText = $oldSelect.children('option:selected').text() || $oldSelect.children('option').first().text().trim();
      $oldSelect.siblings().find('.js-select-box-label').text(selectedText);
      if ($oldSelect.attr('aria-describedby') === undefined) {
        var $newLabelValue = $('<div class="js-select-option"></div>');
        var $required = $oldSelect.siblings().find('.js-select-box-label');

        //Adding aria-label
        if ($required.attr('aria-label') === '' || $required.attr('aria-label') === undefined) {
          $required.attr('aria-label', $oldSelect.children('option').first().text().trim());
        }

        //Making the options read while selecting
        var optionLabel = $required.attr('id') + '--label';
        $required.attr({'aria-describedby': optionLabel});
        $newLabelValue.text(selectedText).attr({
          'id': optionLabel
        });
        $newLabelValue.appendTo($required);
      }
    },

    /**
     * Toggles the new select box's options div dropdown.
     * @param {Object} $newSelect - replacement .js-select-box div element.
     */
    toggleOptions: function($newSelect) {
      if (!$newSelect.hasClass('js-select-box-disabled')) {
        $newSelect.children('.js-select-box-options').toggleClass('open');
        $newSelect.children('.js-select-box-label').toggleClass('open');
      }
    },

    /**
     * Similar to toggleOptions but strictly closes the div dropdown.
     * @param {Object} $newSelect - replacement .js-select-box div element.
     */
    closeOptions: function($newSelect) {
      $newSelect.children('.js-select-box-options').removeClass('open');
      $newSelect.children('.js-select-box-label').removeClass('open');
    },

    /**
     * Updates the hidden select element with the matching option
     * according to what was clicked in the new select box. Then triggers
     * the appropriate event on the old select element.
     * @param {Object} $option - one of the new js-select-box-option div elements.
     */
    updateOldSelect: function($option) {
      var $newSelect = $option.closest('.js-select-box');
      var $oldSelect = $newSelect.siblings('select');

      $oldSelect.children('option').each(function() {
        if ($option.val() === this.value) {
          $oldSelect.val(this.value);
        }
      });

      $oldSelect.change();
    },

    /*
     * Refreshes the new select box with up-to-date markup
     * from the old select element.
     * @param {Object} $newSelect - replacement .js-select-box div element.
     */
    refresh: function($newSelect) {
      var $oldSelect = $newSelect.siblings('select');
      this.replace($oldSelect);
    },

    /**
     * Sets up events for controlling the select box.
     * @type {Object} $oldSelect - the replaced select element.
     */
    setEvents: function($oldSelect) {
      var self = this;
      var $newSelect = $oldSelect.siblings('.js-select-box');

      // Toggle visibility of dropdown.
      $newSelect.unbind('click').on('click', function(event) {
        event.stopPropagation();

        // Close all the other select dropdowns.
        $('.js-select-box').not(this).each(function() {
          self.closeOptions($(this));
        });

        // Toggle open the particular select that was clicked.
        self.toggleOptions($(this));
      });

      //ADA Book appointment page dropdown arrow key navigation
      $('.js-select-box-label', $newSelect).on('focusin', function() {
        var $selectBox = $('.js-select-box-label', $newSelect).parent();
        $(this).attr({'aria-label': $selectBox.attr('aria-label'), 'aria-required': $selectBox.attr('aria-required'), 'aria-describedby': $selectBox.attr('aria-describedby')});
      });
      $('.js-select-box-label', $newSelect).on('keydown', function(e) {
        var $target;
        var keyCode = site.getKeycode(e);
        switch (keyCode) {
          case 13:
            $newSelect.trigger('click');
            if ($(this).hasClass('open')) {
              $(this).attr('aria-expanded', 'true');
              $target = $(this).parent().find('.js-select-box-option:eq(0)');
              $target.addClass('custom-outline').focus();
            } else {
              $(this).attr('aria-expanded', 'false').focus();
            }
            break;
          case 40:
            $target = $(this).parent().find('.js-select-box-option:eq(0)');
            $target.addClass('custom-outline').focus();
            break;
          case 27:
            $(this).trigger('clickoutside');
            $newSelect.focus();
            return false;
            break;
        }
      });
      $('.js-select-box-option').off('keydown').on('keydown', function(e) {
        e.preventDefault();
        var $target = $(this);
        var keyCode = site.getKeycode(e);
        if ($target.hasClass('custom-outline')) {
          $target.removeClass('custom-outline');
        }
        switch (keyCode) {
          case 40:
            $target.next().addClass('custom-outline').focus();
            break;
          case 38: //Up Arrow
            $target.prev().addClass('custom-outline').focus();
            break;
          case 13: //Enter Key
            $(this).parent().prev().attr('aria-expanded', 'false').focus();
            $('.js-select-box-option').attr('aria-selected', 'false');
            $target.attr('aria-selected', 'true');
            $(this).trigger('click');
            $('.js-select-box-options').removeClass('open');
            break;
          case 27: //ESC Key
            $(this).trigger('clickoutside');
            $newSelect.focus();
            break;
        }
      });

      // Close the dropdown options when clicked outside of the select.
      $newSelect.unbind('clickoutside').on('clickoutside', function() {
        self.closeOptions($(this));
      });

      // When an option is selected, put the value of the
      // old hidden select and update the label.
      $('.js-select-box-option', $newSelect).on('click', function() {
        var $this = $(this);
        self.updateOldSelect($this);
        self.setLabel($oldSelect);
      });

      // Update the select box label when a sku is selected.
      $(document).on('product.skuSelect', function(e, skuBaseId) {
        self.setLabel($oldSelect);
      });

      // Only set this event if the $oldSelect is a sku select menu.
      // Update both the <select> and its replacement. This is used
      // for shade routing on SPP page load.
      if ($oldSelect.hasClass('js-sku-menu')) {
        $(document).on('product.initSelects', '.js-product', function(e, skuBaseId) {
          self.setLabel($oldSelect);
        });
      }
    },

    /**
     *  Sets up events related to selects that may not be on the page at first.
     */
    setRefreshEvents: function() {
      var self = this;

      // On quickshop load, call the appropriate shade picker setup methods.
      $(document).on('product.quickshop.launch.inline product.quickshop.launch.inlineCarousel product.quickshop.launch.overlay product.launch.inlineCarousel', function() {
        self.initAll($(this));
      });

      // On re-rendering of panels in checkout, replace the select elements on the page.
      $(document).on('checkout.panels.rendered', function() {
        self.initAll();
      });

      // On refreshing any address forms in checkout, replace the select elements on the page.
      $(document).on('checkout.addressForm.refreshed', function() {
        self.initAll();
      });

      // On disable of a select element, refresh the dropdown to apply the disable property.
      $('select').on('select.disabled', function() {
        self.initOne($(this));
      });

      // On enable, of a select element, refresh the dropdown too.
      $('select').on('select.enabled', function() {
        self.initOne($(this));
      });

      // On refreshing any address forms in account, replace the select elements on the page.
      $(document).on('account.addressForm.refreshed', function() {
        self.initAll();
      });
    },

    /*
     * Little setup method for replacing one particular select element.
     * param {Object} oldSelect - the particular select element
     */
    initOne: function($oldSelect) {
      this.replace($oldSelect);
      this.setLabel($oldSelect);
      this.setEvents($oldSelect);
    },

    /*
     * Little setup method for replacing all select elements in a given context.
     * @param {Object} context - the DOM scope, usually document.
     */
    initAll: function(context) {
      // Turns out IE didn't like $(document) in the default parameter definition
      // so its been moved to inside the method.
      if ((context === undefined) || (context == null)) {
        context = $(document);
      }

      var self = this;

      $('select', context).not('.no-selectjs').each(function() {
        var $oldSelect = $(this);
        self.initOne($oldSelect);
      });
    },

    /**
     * Standard method for attaching this JS.
     * @param {Object} context - the DOM scope, usually document.
     * @param {Object} settings - useful collection of site info.
     */
    attach: function(context, settings) {
      var self = this;

      // Only replace select boxes on desktop.
      if (!self.attached && !site.client.isMobile) {
        self.initAll(context);
        self.setRefreshEvents();
      }
      self.attached = true;
    }
  };
})(jQuery);
